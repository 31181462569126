<template>
  <!-- <ValidationObserver v-slot="{ handleSubmit, invalid }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :disable-position-center="true"
    :modal-show="modal"
    modal-width="60"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Transfer Ownership</span>
    </template>
    <div>
      <div class="flex items-center justify-end gap-3">
        <div class="w-full sm:w-80 pt-5 mx-5 relative mt-2">
          <UiSingleSelect
            v-model="user"
            title="TRANSFER_TO"
            :options="userList"
            label="full_name"
            class="flex-1"
            rules="required|min:8"
            :disabled="disableField"
            :search-through-api="true"
            @search="getAllUsers"
            @deSelect="deSelectCandidateOwner"
            @change="getCandidateOwner"
          />
        </div>
      </div>
    </div>
    <div>
      <UserDetailedView :user="ownerCandidate" />
    </div>
    <div v-if="transferBtn" class="mx-5 border-t border-primary-grey mt-2">
      <div class="sm:w-80 w-full relative pt-7 pb-5">
        <UiInput
          v-model.trim="ownerPassword"
          type="password"
          name="Password"
          title="ENTER_PASSWORD"
          label="Password"
          placeholder="Password"
          class="flex-1"
          rules="min:8|required"
        />
      </div>
    </div>
    <template v-slot:footer>
      <div v-if="transferBtn" class="flex flex-1">
        <button
          v-i18n="dashboard"
          class="focus:outline-none focus:shadow-md sm:text-base text-sm text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
          @click="handleClick('close')"
        >
          Cancel
        </button>
      </div>
      <div class="flex space-x-2 rtl:gap-x-4">
        <button
          class="focus:outline-none focus:shadow-md sm:text-base text-sm text-white font-roboto h-8 rtl:ml-3 flex justify-center items-center primary-button-outline"
          :disabled="isLoading"
          @click="handleClick('firstBtn')"
        >
          {{ firstBtnText }}
        </button>

        <button
          class="focus:outline-none focus:shadow-md sm:text-base text-sm text-white font-roboto h-8 flex justify-center items-center primary-button"
          :disabled="!user"
          @click="handleSubmit(submitForm(invalid))"
        >
          <template v-if="isLoading">
            <Loader></Loader>
          </template>
          <template v-else>
            <span>{{ secondBtnText }}</span>
          </template>
        </button>
      </div>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
/* Components */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
/* 3rd party compononent */
import UserDetailedView from '@src/components/userDetailedView.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { transformGuardianData } from '@src/utils/settings/tenant-user.util.js'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'

/* VUEX */
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    UiModalContainer,
    // ValidationObserver,
    UiSingleSelect,
    Loader,
    UiInput,
    UserDetailedView,
  },
  props: {
    modal: { type: Boolean },
  },
  emits: ['clear', 'cancel', 'close', 'confirm'],
  data() {
    return {
      title: 'title',
      ownerCandidate: null,
      user: null,
      dashboard: 'dashboard',
      isLoading: false,
      disableField: false,
      transferBtn: false,
      ownerPassword: '',
    }
  },
  computed: {
    ...mapState({
      userList: (state) => state.staff.allUsers,
    }),
    firstBtnText() {
      let response = 'Cancel'
      if (this.transferBtn) {
        response = 'Edit'
      }
      return response
    },
    secondBtnText() {
      let response = 'Transfer'
      if (this.transferBtn) {
        response = 'Confirm'
      }
      return response
    },
  },
  created() {
    this.getAllUsers()
  },
  methods: {
    deSelectCandidateOwner() {
      this.ownerCandidate = null
    },
    getCandidateOwner(data) {
      let queryParam = {
        skip: 0,
        limit: 5,
      }
      let query = {
        id: data?.id,
        payload: queryParam,
      }
      this.isLoading = true
      this.getUserDetails(query)
        .then((res) => {
          this.ownerCandidate = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('close', 'cancel')
          this.transferBtn = false
          break
        case 'firstBtn':
          if (!this.transferBtn) {
            this.handleClick('close')
          } else {
            this.disableField = false
            this.transferBtn = false
            this.ownerPassword = ''
          }

          break
      }
    },
    submitForm(invalid) {
      if (invalid) return
      if (!this.transferBtn) {
        this.transferBtn = true
        this.disableField = true
      } else {
        let data = {
          user_id: this.ownerCandidate.id,
          password: this.ownerPassword,
        }
        this.$emit('confirm', data)
      }
    },
    getAllUsers(query = '') {
      this.getUsers({ search: query })
    },

    ...mapActions('staff', ['getUsers', 'getUserDetails']),
  },
}
</script>

<style lang="scss" scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 7px 14px var(--primary-green-dark);
  }
  .step-info {
    color: var(--text-color);
  }
}
@media (max-width: 600px) {
  .primary-button {
    min-width: 70px;
  }
  .primary-button-outline {
    min-width: 70px;
  }
}
</style>
