<template>
  <div class="min-h-screen">
    <div v-if="!isLoading">
      <TitleWrapper
        :filter-option="false"
        title="OWNERSHIP"
        :display-breadcrumb="true"
      ></TitleWrapper>
      <UserDetailedView :user="currentOwner" />
      <TransferOwnerShipModal
        v-if="confirmationForTransferOwnership"
        :modal="confirmationForTransferOwnership"
        @close="toggleconfirmationForTransferOwnershipModal"
        @confirm="transferSuperAdminOwnership"
      />
    </div>
    <div v-else class="mt-28">
      <Loader :content="true" />
    </div>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import ROUTE from '@/src/constants/route-constants.js'
import UserDetailedView from '@src/components/userDetailedView.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import TransferOwnerShipModal from '@src/router/views/transfer_ownership/TransferOwnerShipModal.vue'

export default {
  components: {
    Loader,

    TitleWrapper,
    UserDetailedView,
    TransferOwnerShipModal,
  },
  data() {
    return {
      isLoading: false,
      currentOwner: {},
      confirmationForTransferOwnership: false,
      ROUTE,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  created() {
    this.getOwner()
  },

  methods: {
    transferSuperAdminOwnership(data) {
      this.transferOwnership(data).then(() => {
        this.toggleconfirmationForTransferOwnershipModal()
        this.$store.commit('layout/REMOVE_ITEM')
        this.currentOwner.is_owner = false
        this.removedOwnership(this.currentOwner)
        this.$router?.push({ name: ROUTE.QUICK_ACTIONS.name })
        this.getOwner()
      })
    },

    toggleconfirmationForTransferOwnershipModal() {
      this.confirmationForTransferOwnership = !this.confirmationForTransferOwnership
    },

    setRightBarData() {
      this.setRightbarContent({
        header: {
          title: 'OWNERSHIP',
          buttons: [
            {
              title: 'TRANSFER_OWNERSHIP',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleconfirmationForTransferOwnershipModal()
                },
              },
            },
          ],
        },
      })
    },

    ...mapActions('superadmin', ['transferOwnership']),
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('auth', ['removedOwnership']),
    getOwner() {
      this.currentOwner = this.currentUser
      this.currentOwner.full_name = this.currentOwner.first_name + ' ' + this.currentOwner.last_name
      this.setRightBarData()
    },
  },
}
</script>
